











































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Vue, Component, Watch } from 'vue-property-decorator';
import { prefix } from '@/config/global';
import secondaryMenu from '@/components/secondaryMenu/index.vue';
import loginDropdown from '@/components/loginDropdown/index.vue';
import logo from '@/components/logo/index.vue';
import navMenu from '@/components/navMenu/index.vue';
import { permissionModule } from '@/store/modules/permission';
import { getMallDataProduceStatus } from '@/services/systemApi';
@Component({
  components: {
    loginDropdown,
    navMenu,
    secondaryMenu,
    logo,
  },
})
export default class SystemLayout extends Vue {
  $router: any;
  $beaconAction: any;
  $domainConfig: any;
  $refs: any;
  $stores: any;
  prefix: string = prefix;
  theme = 'dark';
  beforeValue = '';
  $jQ: any;
  img = '';
  nickName = '';
  allLoginList = [
    {
      value: '我的后台',
      label: '/manage',
    },
    {
      value: '精准营销',
      label: '/dmp',
    },
    {
      value: '退出',
      label: '/logout',
    },
  ];
  loginQList: any = [];
  clearTime: any;
  lRouterId = 0;
  routerId = 0;
  childrenList: any = [];
  currentValue = '';
  cCurrentValue = '0';
  unqireVal = '';
  openName = [''];
  showMenu = true;
  isCcollapsible = true;
  sliderShow = true;
  wrapWidth = 'calc(100vw - 200px)';
  layoutContentStyle = {
    width: '100vw',
  };
  contentWidth = this.wrapWidth;
  beforeContentWidth = this.wrapWidth;
  showPage = '';

  mapPath = '/system/intensity/map';
  $dayjs: any;
  initSider = 0;
  showDataProdeceStatus = false;
  showDetailMsg = true;
  timer: any = null;
  productionTime = '下午';
  get menuList(): any {
    return permissionModule.routes[2].children;
  }

  get lbsDmp(): number {
    return permissionModule.lbsDmp;
  }

  get computedRightPx(): any {
    // || this.$route.path.includes('touristTop')
    console.log(this.$route.path);
    if (
      this.$route.path.includes('report')
      || this.$route.path.includes('distribution')
      || this.$route.path.includes('/system/source/touristTop/portrait')
      || this.$route.path.includes('/system/intensity/analysis1/distributed')
    ) {
      return '40px';
    }
    if (this.$route.path.includes('/system/source/touristTop')) {
      return '100px';
    }
    if (this.$route.path.includes('/system/source/depression') || this.$route.path.includes('/system/source/tourist')) {
      return '200px';
    }

    return '100px';
  }

  mounted(): void {
    this.isCcollapsible = false;
    if (this.lbsDmp === 1) {
      this.loginQList = this.allLoginList;
    } else {
      const [a, , c] = this.allLoginList;
      this.loginQList = [a, c];
    }
    this.setMallDataProduceStatus();
  }

  // 查询数据生产状态
  async setMallDataProduceStatus() {
    const currentTime = this.$dayjs();
    const startTime = this.$dayjs().set('hour', 8)
      .set('minute', 0)
      .set('second', 0);

    const m = this.$dayjs().set('hour', 15)
      .set('minute', 0)
      .set('second', 0);
    this.productionTime = currentTime.isAfter(m) ? '晚上' : '下午';

    if (currentTime.isAfter(startTime)) {
      const res = await getMallDataProduceStatus();
      if (res.status === 1) {
        // 数据未生产
        this.showDataProdeceStatus = true;
        this.showDetailMsg = true;
        this.timer = setTimeout(() => {
          this.showDetailMsg = false;
        }, 5000);
        return;
      }
    }
    this.showDataProdeceStatus = false;
  }
  prodeceStatusEnter() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.showDetailMsg = true;
  }

  clearTimes() {
    if (this.clearTime) {
      clearTimeout(this.clearTime);
      this.clearTime = null;
    }
  }

  // 头部导航栏change
  selectChangeHandler(obj: any): void {
    const { childrenList, currentPath, cCurrentPath, openName, name } = obj;
    this.childrenList = childrenList;
    let path = currentPath;
    if (path.includes('report')) {
      path = '/report';
    }
    this.currentValue = path;
    this.cCurrentValue = cCurrentPath;
    this.$nextTick(() => {
      (this.$refs.menuWrap as any).updateOpened();
      (this.$refs.menuWrap as any).updateActiveName();
    });
    this.$beaconAction.onUserAction('menu_click', {
      menu: name,
    });
    const pathObj = this.getRouterPath(cCurrentPath);
    const { paths } = pathObj;
    if (cCurrentPath !== '/system/intensity/map') {
      sessionStorage.setItem(`${paths}RouterPath`, cCurrentPath);
    }
    this.openName = openName;
    this.sliderHide(cCurrentPath);
    this.mallUpdate();
    this.clearTimes();
    this.clearTime = setTimeout(() => {
      const { path, meta } = this.$route;
      const { id } = meta;
      this.routerId = Number(id.split('-')[0]);
      const obj = {
        id,
        path: cCurrentPath,
      };
      const paths = path.split('/');
      const path2 = paths[2];
      const { nCount } = this.$store.state.mall.competedModelObj;
      if (nCount === 0 && path2 === 'intensity') {
        this.$router.push(this.mapPath);
        this.$refs.secondaryMenu.containerHideByPath(id, obj);
      } else {
        this.$router.push(this.cCurrentValue);
        this.$refs.secondaryMenu.containerHideByPath(id, obj);
      }
    }, 100);
  }

  getRouterPath(path: string): any {
    const pathAry = path.split('/');
    const paths = pathAry[2];
    const currentPath = `${pathAry[0]}/${pathAry[1]}/${pathAry[2]}`;
    const currentPath2 = `${pathAry[0]}/${pathAry[1]}/${pathAry[2]}/${pathAry[3]}`;
    const openName = [currentPath2];
    return {
      paths,
      currentPath,
      cCurrentPath: path,
      openName,
    };
  }

  getPath(list: any): string {
    let path = '';
    const getStr = function (list: any) {
      list.forEach((item: any, index: number) => {
        if (item.children) {
          getStr(item.children);
        } else if (index === 0) {
          path = item.path;
        }
      });
    };
    getStr(list);
    return path;
  }

  // 导航栏changer执行代码
  initRouterChangeHandler(): void {
    // 进入系统；
    let { path } = this.$route;

    if (path === '/system') {
      path = this.getPath([this.menuList[0]]);
      this.$router.push(path);
    }
    const router = this.getRouterPath(path);
    const { paths, currentPath, cCurrentPath, openName } = router;
    let childrenList = {};
    let parentObj: any = {};
    this.menuList.forEach((item: any) => {
      const { path, children } = item;
      if (path.includes(paths)) {
        childrenList = children;
        parentObj = item;
      }
    });
    const obj = {
      childrenList,
      currentPath,
      cCurrentPath,
      openName,
      name: parentObj.name,
    };
    this.selectChangeHandler(obj);
  }

  // 侧边导航栏changer
  cSelectChangeHandler(val: string): void {
    if (this.unqireVal === val) return;
    this.unqireVal = val;
    this.$router.push(val);
    const { paths } = this.getRouterPath(val);
    this.mallUpdate();
    sessionStorage.setItem(`${paths}RouterPath`, val);
    this.$nextTick(() => {
      const { id } = this.$route.meta;
      this.routerId = Number(id.split('-')[0]);
      // this.$refs.secondaryMenu.containerHideByPath(id, obj);
    });
  }

  // 左侧边栏changer事件
  siderChangerHandler(status: boolean): void {
    const width = status ? 'calc(100vw - 64px)' : this.wrapWidth;
    if (this.initSider === 0) {
      this.initSider = 1;
    } else {
      this.contentWidth = width;
      this.layoutContentStyle = { width: this.contentWidth };
      this.beforeContentWidth = this.contentWidth;
    }
  }

  // 左侧边栏隐藏代码
  sliderHide(path: string): void {
    const { nCount } = this.$store.state.mall.competedModelObj;
    const paths = path.split('/')[2];
    if (paths === 'report' || (paths === 'intensity' && nCount === 0)) {
      this.sliderShow = false;
    } else {
      this.sliderShow = true;
    }
    this.contentWidth = this.sliderShow ? this.beforeContentWidth : '100vw';
    this.layoutContentStyle = { width: this.contentWidth };
  }

  @Watch('$store.state.mall.competedModelObj', { deep: true })
  changeCompeted(): void {
    const { path } = this.$route;
    this.sliderHide(path);
  }

  @Watch('$store.state.pageConfig.mallUpdateKey')
  changeMallUpdateKey(): void {
    this.mallUpdate();
  }

  @Watch('menuList', { immediate: true, deep: true })
  changeMenulist(): void {
    this.initRouterChangeHandler();
  }

  @Watch('$route')
  changeRouter(e: any): void {
    const { path, meta } = e;
    const { id } = meta;
    const obj = {
      id,
      path,
    };
    this.$refs.secondaryMenu.containerHideByPath(id, obj);
    this.$nextTick(() => {
      this.$stores.mall.setRouoter(path);
    });
  }

  paramsChangeHandler(): void {
    this.mallUpdate();
  }

  mallUpdate(): void {
    const { path } = this.$route;
    const pathAry = path.split('/');
    const path2 = pathAry[2];
    if (path2 === 'environment' || path2 === 'source') {
      this.showPage = String(new Date().getTime());
    }
  }
}
